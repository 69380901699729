<!--
 * @Author: 码上talk|RC
 * @Date: 2021-05-05 08:09:28
 * @LastEditTime: 2021-06-07 16:52:58
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /m/src/App.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  created () {
    this.$log4js.debug('App is started');
  }
};
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
    Microsoft YaHei, "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif;
  line-height: 1.5;
  font-size: 22px;
  color: #515a6e;
}
</style>
