<!--
 * @Author: 码上talk|RC
 * @Date: 2021-06-13 15:39:58
 * @LastEditTime: 2022-01-15 17:03:54
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-m/src/components/file-previewer/index.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="file-previewer">
    <div class="l-list">
      <div class="l-item" :key="key" v-for="(item, key) in fileList">
          <img :src="item.oss.url" alt @click="showPopup(item)" />
      </div>
    </div>
  </div>
</template>

<script>
  import popup from '../popup';
  import viewer from './viewer';
  import {
    model
  } from '@/model';

  const {
    Oss
  } = model.collection;

  export default {
    props: {
      fileList: {
        type: Array,
        default () {
          return [];
        }
      }
    },
    methods: {
      showPopup(item) {
        popup((h) => {
          return h(viewer, {
            props: {
              src: new Oss(item.oss).fullUrl()
            }
          });
        }, {
          model: 'center'
        });
      }
    }
  };
</script>

<style lang="less">
  .file-previewer {
    .l-list {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .l-item {
        width: 150px;
        height:150px;
        background: #f7f8fa;
        margin-left: 20px;

          img {
            width: 100%;
            height: 100%;
            background-size: cover;
          }
      }
    }
  }
</style>
