<template>
  <div class="apply-progress">
    <van-sticky>
      <div class="p-steps">
        <steps :active="step" :steps="steps"></steps>
      </div>
    </van-sticky>
    <template v-if="isFormShow">
      <deposit-form v-if="step === 0" ref="depositForm" :p-manager="pManager"></deposit-form>
      <address-form v-if="step === 1" ref="addressForm" :p-manager="pManager"></address-form>
      <sign-form v-if="step === 2" ref="signForm" :p-manager="pManager"></sign-form>
      <decorate-form v-if="step=== 3" ref="decorateForm" :p-manager="pManager"></decorate-form>
    </template>
    <template v-else>
      <a-empty :src="statusInfo.src | prefixOssUrl" :tips="statusInfo.tips"></a-empty>
      <div class="p-remark" v-if="step === 1 && latestAuditStatus === 1">
        <div class="r-label">待办事项:</div>
        <div class="r-content">
          <span class="c-text">1.请联系信息部完成新店主进货。</span>
          <span class="c-btn" @click="go('/apply-approval/customer-service-contact')">我要进货></span>
        </div>
      </div>
      <template v-if="[1,3].includes(status) && latestAuditStatus === 2 ">
        <div v-if="remark" class="p-remark">
          <div class="r-label">备注:</div>
          <div class="r-content">{{ remark }}</div>
        </div>
      </template>
    </template>

    <div class="p-btns" v-if="isFormShow">
      <div class="b-btn b-btn_main" @click="submitDepositForm" v-if="step === 0">已支付，上传凭证</div>
      <div class="b-btn b-btn_main" @click="submitAddresForm" v-if="step === 1">确认提交</div>
      <div class="b-btn b-btn_main" @click="submitSignForm" v-if="step === 2">确认提交</div>
      <div class="b-btn b-btn_main" @click="submitDecorateForm" v-if="step === 3">确认提交</div>
    </div>
    <template v-else>
      <div class="p-btns" v-if="status === 1  && latestAuditStatus === 2">
        <div class="b-btn b-btn_main" :class="isFormShow ? '' : 'fixed' " @click="reSubmit()">重新提交</div>
      </div>
      <div class="p-btns" v-else>
        <div class="b-btn" v-if="step==-1" :class="isFormShow ? '' : 'fixed' " type="primary" hollow size="large"
          @click="dialog.cancel = true">取消</div>
        <div class="b-btn b-btn_main" :class="isFormShow ? '' : 'fixed' "
          v-if="step === 0 && !isAuditing && latestAuditStatus === 1" @click="showForm">缴纳市场保证金</div>
        <div class="b-btn b-btn_main" :class="isFormShow ? '' : 'fixed' " v-if="[1, 2, 3].includes(step) && !isAuditing"
          @click="showForm">下一步</div>
      </div>
    </template>

    <a-dialog :show="dialog.cancel" type="error" title="提示！" sub-title="您要取消该区域的申请吗？" @close="dialog.cancel=false">
      <template v-slot:btns>
        <div class="a-dialog_btns">
          <div class="b-btn" @click="dialog.cancel=false">再想想</div>
          <div class="b-btn" @click="confirmCancelApply">确定</div>
        </div>
      </template>
    </a-dialog>
  </div>
</template>

<script>
  import ProgressManager from './@js/ProgressManager';
  import steps from './@fragment/steps';
  import depositForm from './@fragment/deposit-form';
  import addressForm from './@fragment/address-form';
  import signForm from './@fragment/sign-form';
  import decorateForm from './@fragment/decorate-form';
  import aBtn from '@/components/btn';
  import aDialog from '@/components/dialog';
  import aEmpty from '@/components/empty';
  import eventBus from '@/event';
  import {
    eventApproval
  } from '@/event/approval'
  import {
    model
  } from '@/model';

  const {
    Approval
  } = model.collection;

  const ApprovalStatus = {
    AUDITING: 1,
    SUCCESS: 2,
    REJECT: 3,
    CANCEL: 4
  }

  export default {
    components: {
      steps,
      depositForm,
      addressForm,
      signForm,
      decorateForm,
      aBtn,
      aEmpty,
      aDialog
    },
    metaInfo() {
      return {
        title: this.title
      }
    },
    data() {
      return {
        title: '店铺申请进度',
        id: '',
        pManager: new ProgressManager(),
        steps: [],
        dialog: {
          cancel: false
        },
        approvalInfo: {},
        approvalTimelineList: []
      }
    },
    created() {
      const {
        id,
        type
      } = this.$route.query
      this.id = id
      this.title = type === 2 ? '店铺申请进度' : '区域申请进度'
      this.getProgressInfo()
    },
    computed: {
      isAuditing() {
        return this.pManager.isAuditing
      },
      status() {
        return this.pManager.info.status
      },
      step() {
        return this.pManager.step
      },
      statusInfo() {
        return this.pManager.statusInfo
      },
      isFormShow() {
        return this.pManager.isFormShow
      },
      timeLine() {
        return this.pManager.timeLine
      },
      remark() {
        return this.pManager.remark
      },
      latestAuditStatus() {
        return this.pManager.latestAuditStatus
      },
    },
    methods: {
      addStep() {
        this.pManager.step++
        if (this.pManager.step > 4) {
          this.pManager.reset()
        }
      },
      showForm() {
        this.pManager.showForm()
      },
      reSubmit() {
        this.pManager.isFormShow = true
        this.pManager.isResubmit = 1
      },
      getProgressInfo() {
        return Approval.sendApi('info', {
          params: {},
          data: {
            query: {
              id: this.id
            }
          }
        }).then((res) => {
          if (res.status) {
            this.pManager.setApprovalInfo(res.data)
            this.approvalTimelineList = res.data.approvalTimelineList
            this.steps = this.pManager.flowList.map((f, i) => {
              return {
                name: f.name
              }
            })
            this.steps.push({
              name: '通过'
            })
          }
        }).catch((e) => {})
      },
      confirmCancelApply() {
        Approval.sendApi('updateStatus', {
          params: {
            id: this.pManager.info.id,
            status: ApprovalStatus.CANCEL
          },
          data: {}
        }).then((res) => {
          if (res.status) {
            eventBus.$emit(eventApproval.FRESH_MY_APPROVAL_LIST)
            this.back()
          }
        })
      },
      submitDepositForm() {
        let depositForm = this.$refs.depositForm.form,
          imgList = this.$refs.depositForm.imgList
        depositForm.fileDeposit = JSON.stringify(imgList)
        let form = this.pManager.buildForm(depositForm)
        if (!form[this.pManager.typeKey].depositPaymentType) {
          this.$toast('请选择付款方式')
        } else if (!form[this.pManager.typeKey].depositPayer) {
          this.$toast('请填写付款人姓名')
        } else if (imgList.length == 0) {
          this.$toast('请上传转账截图')
        } else {
          this.commonSubmit('上传凭证确认', form, depositForm, '上传成功')
        }
      },
      submitAddresForm() {
        let addressForm = this.$refs.addressForm.form
        let form = this.pManager.buildForm(addressForm)
        if (!form[this.pManager.typeKey].addressFull) {
          this.$toast('请填写店铺地址')
        } else if (!form[this.pManager.typeKey].addressArea) {
          this.$toast('请填写店铺面积')
        } else if (!form[this.pManager.typeKey].addressContact) {
          this.$toast('请填写街道电话')
        } else {
          this.commonSubmit('地址提交', form, addressForm)
        }
      },
      submitSignForm() {
        let imgList = this.$refs.signForm.imgList
        if (imgList.length == 0) {
          this.$toast('请上传租赁合同图片')
        } else {
          let signForm = {
            fileTenancyAgreement: JSON.stringify(imgList)
          }
          let form = this.pManager.buildForm(signForm)
          this.commonSubmit('租赁合同提交', form, signForm)
        }
      },
      submitDecorateForm() {
        let imgList = this.$refs.decorateForm.imgList
        if (imgList.length == 0) {
          this.$toast('请上传店铺装修照片')
        } else {
          let signForm = {
            fileFixture: JSON.stringify(imgList)
          }
          let form = this.pManager.buildForm(signForm)
          this.commonSubmit('装修照片上传', form, signForm)
        }
      },
      commonSubmit(tips, form, innerForm, resText = '上传成功') {
        this.$dialog.confirm({
          title: tips
        }).then(() => {
          Approval.sendApi('update', {
            params: {
              id: this.id
            },
            data: form
          }).then((res) => {
            if (res.status) {
              this.$toast(resText)
              if (this.pManager.info.status == ApprovalStatus.AUDITING) {
                this.pManager.isFormShow = false
                this.pManager.updateInfo(innerForm)
                eventBus.$emit('updateApprovalItem', this.pManager.info, innerForm)
              }
            }
          }).then(() => {
            if (this.pManager.info.status == ApprovalStatus.REJECT) {
              return this.pManager.remoteUpdateStatus(ApprovalStatus.AUDITING)
            }
          }).then(() => {
            return this.getProgressInfo().then(() => {
              this.pManager.isFormShow = false
            })
          }).finally(() => {
          })
        }).catch((e) => {})
      }
    }
  }
</script>

<style lang="less">
  .apply-progress {
    min-height: 100%;

    .p-steps {
      padding: 40px 30px;
      background: #fff;
    }

    .p-remark {
      margin: 30px;

      .r-label {
        font-size: 32px;
        color: #373737;
        font-weight: 500;
      }

      .r-content {
        display: flex;
        min-height: 150px;
        margin-top: 29px;
        padding: 31px 25px;
        font-size: 30px;
        color: #999;
        background: #f7f7f7;

        .c-text {
          line-height: 60px;
        }

        .c-btn {
          padding: 0 10px;
          height: 60px;
          line-height: 60px;
          color: #db187c;
          font-size: 30px;
        }
      }
    }

    .p-btns {
      margin-top: 40px;
      margin-bottom: 30px;
      padding: 0 30px;

      .b-btn {
        width: 690px;
        height: 90px;
        text-align: center;
        line-height: 90px;
        font-size: 32px;
        color: #db187c;
        border: 1px solid #db187c;
        border-radius: 10px;

        &_main {
          color: #fff;
          background: #db187c;
        }
      }

      .fixed {
        position: fixed;
        bottom: 50px;
      }
    }



    .a-dialog_btns {
      display: flex;
      justify-content: space-around;
      width: 500px;

      .b-btn {
        width: 200px;
        height: 60px;
        line-height: 60px;
        border-radius: 30px;
        text-align: center;
        border: 1px solid #db187c;
        color: #db187c;

        &:nth-of-type(2) {
          margin-left: 10px;
          background: #db187c;
          color: #fff;
        }
      }
    }

    .form {
      .axq-form {
        &-item {
          margin-top: 0;

          .upload {
            width: 690px;
          }

          a-input {
            flex: 1;

            .axq-input__inner {
              text-align: right;
            }
          }

          a-select {
            flex: 1;

            .iconfont {
              padding: 0 0 0 5px;
            }
          }
        }

        .upload-item {
          .axq-form-item {
            flex-wrap: wrap;
            border: none;
          }

          &:last-of-type {
            .axq-form-item {
              border: none;
            }
          }
        }

        .unit {
          padding-left: 5px;
          display: inline-block;
          color: #666;
          font-size: 32px;
          vertical-align: top;
        }
      }

      .tips {
        margin-bottom: 29px;
        font-size: 24px;
        color: #ff0000;
      }
    }
  }

  /deep/.van-uploader__mask {
    display: none !important;
  }
</style>