<template>
  <div class="piker-region">
    <div class="r-btns">
      <div class="btn cancel" @click="handleCancel">取消</div>
      <div class="btn confirm" @click="handleConfirm">确认</div>
    </div>
    <div class="r-tabs">
      <div class="t-list" v-if="labels.length !== 0">
        <div v-for="(l,i) in labels" :key="i" class="t-item"
          :class="active && l.id === activeIndex ? 't-item--active':'' "
          @click="handleTabChange(l)">{{l.name}}</div>
      </div>
    </div>
    <div class="r-main" :style="{height:customHeight+'px'}">
      <div class="m-list">
        <div v-for="(r,i) in regionList" :key="i" @click="handleRegionSelect(r)" class="l-item" 
        :class="activeIndex === r.id ? '-item--active' : ''
         ">{{r.name}}</div>
      </div>
      <div class="m-empty" v-if="regionList.length === 0">暂无数据</div>
    </div>
  </div>
</template>

<script>
  import {
    model
  } from '@/model';

  const {
    Request
  } = model;

  export default {
    props:{
      customHeight:[String,Number]
    },
    data() {
      return {
        active:false,
        activeIndex:'',
        request: new Request(),
        labels: [],
        regionList: []
      }
    },
    created() {
      this.getRegion();
    },
    methods: {
      handleTabChange(e) {
        if (e.id === this.activeIndex) {
          this.active = true
        }
        this.getRegion(e.pId);
        if(this.labels.length === 3 ) {
          if(e.id === this.labels[1].id) {
            this.labels.splice(2, 1)
          }
        }
        if(e.level === 'province') {
          this.labels.splice(1, 1)
        }
      },
      handleRegionSelect(e) {
        this.activeIndex = e.id;
        // 香港澳门情况
        if ([34992, 35011].includes(e.pId)) {
          this.labels.splice(1, 1, e)
          return
        }
        // 中山，东莞情况
        if ([1100, 1895].includes(e.pId)) {
          this.labels.splice(2, 1, e)
          return
        }
        if (e.level === 'province') {
          this.labels.splice(0, 1, e)
          // 重选省份重置区，街道
          if (this.labels.length > 1) {
            if (e.id !== this.labels[1].pId) {
              this.labels.splice(1, this.labels.length - 1)
            }
          }
        }
        if (e.level === 'city') {
          this.labels.splice(1, 1, e)
        }
        if (e.level === 'district') {
          this.labels.splice(2, 1, e)
          return
        }
        this.getRegion(e.id);
      },
      handleConfirm() {
        let selectValues = this.labels
        this.$emit('confirm', {
          values: selectValues,
          pcds: {
            provinceId: this.$_.get(selectValues, '[0].id'),
            cityId: this.$_.get(selectValues, '[1].id'),
            districtId: this.$_.get(selectValues, '[2].id'),
            status: '2,3,4'
          }
        })
      },
      handleCancel() {
        this.$emit('cancel');
      },
      getRegion(pId = 0) {
        this.request.do('open', 'enum', 'lst', {
          params: {
            key: 'sysRegion'
          },
          data: {
            query: {
              pId
            }
          }
        }).then(res => {
          if (res.status) {
            this.regionList = res.data
          } else {
            throw new Error('API error:region.lstByPid')
          }
        })
      }
    }
  }
</script>

<style lang="less">
  .piker-region {

    .r-btns {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 32px;
      font-weight: bold;
      color: #383838;

      .btn {
        width: 150px;
        height: 60px;
        line-height: 60px;
        text-align: center;
      }

      .confirm {
        color: #db187c;
      }
    }

    .r-tabs {
      margin-bottom: 20px;
      width: 100%;
      height: 90px;

      .t-list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;

        .t-item {
          position: relative;
          width: 120px;
          margin: 0 10px;
          line-height: 60px;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &--active {
            color: #db187c;
            &:after {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 22px;
              width: 80px;
              height: 3px;
              background: #db187c;
            }
          }
        }
      }
    }

    .r-main {
      width: 100%;
      overflow-y: auto;

      .m-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        box-sizing: border-box;

        .l-item {
          width: 17%;
          margin: 10px 20px;
          padding: 10px;
          text-align: center;
          font-size: 30px;
          font-weight: bold;
          color: #a7a7a7;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &--active {
            border-radius: 20px;
            background-color: #db187c;
            color: #fff;
          }
        }
      }

      .m-empty {
        width: 100%;
        height: 100px;
        text-align: center;
        line-height: 100px;
        font-size: 32px;
        color: #a1a1a1;
      }
    }
  }
</style>