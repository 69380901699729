/*
 * @Author: 码上talk|RC
 * @Date: 2021-07-03 15:34:12
 * @LastEditTime: 2022-01-11 16:54:24
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/store/user/mutations.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */

import * as types from './mutation-types';

export const mutations = {
  [types.SET_MEMBER_INFO] (state, o) {
    state.info = o;
  }
};
