<!--
 * @Author: 码上talk|RC
 * @Date: 2021-08-04 16:41:48
 * @LastEditTime: 2022-01-15 14:50:55
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/apply/approval/customer-service-contact.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="new-shop-stock">
    <div class="s-title">新店主进货请联系信息部-玲玲</div>
    <div class="s-card">
      <div class="c-title">联系方式</div>
      <div class="c-info">
        <div class="i-item" @click="copy(wxUsername)">
          <span class="i-label">微信：</span>
          <div class="i-value">
            {{ wxUsername||'--' }}
            <span class="v-text">复制</span>
          </div>
        </div>
        <div class="i-item" @click="copy(mobile)">
          <span class="i-label">电话：</span>
          <span class="i-value">
            {{ mobile||'--' }}
            <span class="v-text">复制</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    metaInfo() {
      return {
        title: '新店主进货'
      }
    },
    data() {
      return {
        wxUsername: 'aoxueqilingling',
        mobile: '15815927424'
      }
    }
  }
</script>

<style lang="less">
  .new-shop-stock {
    padding: 0 30px;

    .s-title {
      display: block;
      padding: 30px 0;
      font-size: 36px;
      color: #333;
    }

    .s-card {
      background: #f7f7f7;
      border-radius: 20px 24px;

      .c-title {
        padding: 36px 24px;
        font-size: 30px;
        font-weight: bold;
        color: #333;
        border-bottom: 2px solid #e5e5e5;
      }

      .c-info {
        padding: 0 24px;

        .i-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 90px;

          .i-label {
            font-size: 30px;
            color: #666;
          }

          .i-value {
            flex: 1;
            text-align: right;
            font-size: 30px;
            color: #333;

            .v-text {
              margin-left: 20px;
              color: #DB187C;
            }
          }

          &:first-of-type {
            border-bottom: 2px solid #e6e6e6;
          }
        }
      }


    }
  }
</style>