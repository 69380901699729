/*
 * @Author: 码上talk|RC
 * @Date: 2021-04-22 11:34:59
 * @LastEditTime: 2022-01-10 16:22:17
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-m/src/model/index.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import collection from './collection';
import { Request } from './request';

export const model = {
  collection,
  Request
};
