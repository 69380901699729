/*
 * @Author: 码上talk|RC
 * @Date: 2021-05-05 08:09:30
 * @LastEditTime: 2022-06-23 16:02:20
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-m/src/router/routes.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
export const routes = [
  {
    name: 'index',
    path: '/',
    component: () => import('@/views/index'),
  },
  {
    name: '500',
    path: '/500',
    component: () => import('@/views/500')
  },
  {
    name: 'apply',
    path: '/apply/pending',
    component: () => import('@/views/apply/pending'),
  },
  {
    name: 'apply',
    path: '/apply/index',
    component: () => import('@/views/apply/index'),
  },
  {
    name: 'applyRegionSearch',
    path: '/apply/region/search/index',
    component: () => import('@/views/apply/region/search/index'),
  },
  {
    name: 'applyRegionSearchResult',
    path: '/apply/region/search/result',
    component: () => import('@/views/apply/region/search/result'),
  },
  {
    name: 'applyRegionAdd',
    path: '/apply/region/add',
    component: () => import('@/views/apply/region/add'),
  },
  {
    name: 'applyShopSearch',
    path: '/apply/shop/search',
    component: () => import('@/views/apply/shop/search'),
  },
  {
    name: 'applyShopAdd',
    path: '/apply/shop/add',
    component: () => import('@/views/apply/shop/add'),
  },
  {
    name: 'applyApprovalList',
    path: '/apply/approval/list',
    component: () => import('@/views/apply/approval/list'),
  },
  {
    name: 'applyApprovalFlow',
    path: '/apply/approval/_id',
    component: () => import('@/views/apply/approval/_id'),
  },
  {
    name: 'applyApprovalCustomerServiceContact',
    path: '/apply/approval/customer-service-contact',
    component: () => import('@/views/apply/approval/customer-service-contact'),
  },
  {
    name: 'traceQuery',
    path: '/trace/query',
    component: () => import('@/views/trace/query'),
  },
  {
    name: 'axqFeedback',
    path: '/axq/feedback/index',
    component: () => import('@/views/axq/feedback/index'),
  },
  {
    name: 'axqFeedbackAdd',
    path: '/axq/feedback/add',
    component: () => import('@/views/axq/feedback/add'),
  }
];
