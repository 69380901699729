<template>
  <div class="deposit-form">
    <div class="f-item">
      <span class="i-text">申请人</span>
      <span class="i-text">
        {{ pManager.info.typeId === 1 ? this.$_.get(pManager,'info.approvalSubRegionAllocateAgent.applier.nickname') 
          : this.$_.get(pManager,'info.approvalSubShop.applier.nickname') }}
      </span>
    </div>
    <div class="f-item">
      <span class="i-text">联系电话</span>
      <span class="i-text">
        {{ pManager.info.typeId === 1 ? this.$_.get(pManager,'info.approvalSubRegionAllocateAgent.applier.mobile') 
          : this.$_.get(pManager,'info.approvalSubShop.applier.mobile') }}
      </span>
    </div>
    <div class="f-item">
      <span class="i-text">申请地区</span>
      <span class="i-text i-text_region">{{ this.$_.get(pManager,'info.name') }}</span>
    </div>
    <div class="f-item">
      <span class="i-text">市场保证金</span>
      <span class="i-text">5000.00元</span>
    </div>
    <div class="f-item required" @click="openPopup('payWay')">
      <span class="i-text">付款方式</span>
      <span class="i-text">{{ selectPayWay || "请选择付款方式" }}</span>
    </div>
    <div class="f-item required" @click="openPopup('time')">
      <span class="i-text">付款时间</span>
      <span class="i-text">{{ form.depositPayTime || "请选择付款时间" }}</span>
    </div>
    <div class="f-item required">
      <span class="i-text">付款人</span>
      <input class="i-input" placeholder="请填写付款人姓名" v-model="form.depositPayer" />
    </div>
    <div class="f-upload">
      <p class="u-label">上传凭证</p>
      <div class="u-content">
        <div class="c-tip">注：请正确上传转账截图</div>
        <van-uploader ref="uploader" multiple accept="image" :file-list="previewImgList" :max-count="6"
          :after-read="afterRead" @delete="handleDel"></van-uploader>
      </div>
    </div>

    <van-popup v-model="popupShow" position="bottom" round @close="popupShow = false">
      <van-picker v-if="curPopup=='payWay'" :columns="columns" show-toolbar @confirm="handlePayWayConfirm"
        @cancel="popupShow=false"></van-picker>
      <van-datetime-picker v-if="curPopup=='time'" type="date" v-model="curDate" :min-date="minDate"
        @confirm="handleTimeConfirm" @cancel="popupShow=false"></van-datetime-picker>
    </van-popup>
  </div>
</template>

<script>
  import {
    model
  } from '@/model';
  const {
    Request
  } = model;
  export default {
    props: {
      pManager: Object
    },
    data() {
      return {
        request: new Request(),
        form: {
          depositPayer: '',
          depositPayTime: this.$dayjs().format('YYYY-MM-DD'),
          depositPaymentType: ''
        },
        selectPayWay:'',
        columns:[
          {
            text:'银行转账',
            value:1
          },
          {
            text:'现金',
            value:2
          },
          {
            text:'刷卡',
            value:3
          },
          {
            text:'微信',
            value:4
          },
          {
            text:'支付宝',
            value:5
          }
        ],
        curPopup: '',
        popupShow: false,
        curDate: new Date(),
        minDate: new Date(this.$dayjs().add(-1, 'year').format('YYYY-MM-DD')),
        imgList: [],
        previewImgList: []
      }
    },
    methods: {
      setForm(form) {
        this.form = {
          ...this.form,
          ...form
        }
      },
      ossRequest(e) {
        this.request.do('open', 'oss', 'authorize', {
          params: {},
          data: {}
        }).then((res) => {
          if (res.status) return this.upload(res.data, e.file)
        }).then((res) => {
          if (res.status) {
            this.imgList.push({
              _file: e.file,
              info: {
                name: e.file.name,
                size: e.file.size,
              },
              oss: res.data.data,
            })
            this.previewImgList.push(e)
          } else {
            throw new Error()
          }
        }).catch(() => {
          this.$toast('上传图片失败')
        })
      },
      afterRead(e) {
        if (Array.isArray(e)) {
          e.forEach((f) => {
            this.ossRequest(f);
          })
        } else {
          this.ossRequest(e);
        }
      },
      handleDel(event, detail) {
        this.imgList.splice(detail.index, 1)
        this.previewImgList.splice(detail.index, 1)
      },
      openPopup(type) {
        this.curPopup = type
        this.popupShow = true
      },
      handleTimeConfirm(e) {
        this.form.depositPayTime = this.$dayjs(e).format('YYYY-MM-DD');
        this.popupShow = false
      },
      handlePayWayConfirm(e) {
        if(e) {
          const { text,value } = e
          this.selectPayWay = text;
          this.form.depositPaymentType = value;
        }
        this.popupShow = false
      }
    }
  }
</script>

<style lang="less" scoped>
  .deposit-form {
    padding: 0 30px;

    .f-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      margin-top: 20px;
      border-bottom: 1px solid #ccc;

      .i-text {
        font-size: 32px;
        font-weight: 500;
        color: #666666;

        &_region {
          width: 500px;
          text-align: right;
        }
      }

      .i-input {
        border: none;
        text-align: right;
        font-size: 32px;
      }
    }

    .f-upload {
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top: 40px;

      .u-label {
        position: relative;
        font-size: 32px;

        &::before {
          content: '*';
          position: absolute;
          top: 0;
          left: -20px;
          font-size: 40px;
          font-weight: bold;
          color: #FF0000;
        }
      }

      .u-content {
        .c-tip {
          margin: 20px 0;
          font-size: 24px;
          color: #FF0000;
        }
      }
    }

    .required {
      position: relative;

      &::before {
        content: '*';
        position: absolute;
        top: 20px;
        left: -20px;
        font-size: 40px;
        font-weight: bold;
        color: #FF0000;
      }
    }
  }
</style>