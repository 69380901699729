<template>
  <div class="pending">
    <img :src="'empty.png'| prefixOssUrl" />
    <div>区域查询暂未开放，请联系信息部！</div>
  </div>
</template>

<script>
export default {
}
</script>
<style lang="less" scoped>
.pending {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  > img {
    width: 460px;
    height: 460px;
  }
  > div {
    margin-top: 30px;
    font-size: 28px;
    font-weight: 400;
    color: #666;
  }
}
</style>
