/*
 * @Author: 码上talk|RC/3189482282@qq.com
 * @Date: 2021-06-07 14:19:20
 * @LastEditTime: 2022-02-15 10:41:33
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /m/src/libs/js/session.js
 */

import { appConfig } from '@/config/app';

export const sessionLib = {
  set: s => {
    localStorage.setItem(appConfig.tokenKey, s);
  },
  get: () => {
    return localStorage.getItem(appConfig.tokenKey);
  },
  clear: () => {
    localStorage.removeItem(appConfig.tokenKey);
  },
  setAppKey: s => {
    localStorage.setItem(appConfig.appKey, s);
  },
  getAppKey: () => {
    return localStorage.getItem(appConfig.appKey);
  },
};
