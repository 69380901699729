/*
 * @Author: 码上talk|RC
 * @Date: 2021-06-22 14:30:48
 * @LastEditTime: 2022-01-12 14:05:54
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/store/user/actions.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */

import * as types from './mutation-types';
import { model } from '@/model';

const { Member} = model.collection;

export const actions = {
  getUserInfo: ({ dispatch, commit }) => {
    return Member.sendApi('info', { params: {}, data: {} }, { parse4Entity: true }).then(resp => {
      const { status, data } = resp;
      if (status) {
        commit(types.SET_MEMBER_INFO, data);
      }
    })
  }
};
