<template>
  <div class="shop-apply-search">
    <img class="s-header" :src="'apply/search/header-shop.png'|prefixOssUrl" />
    <div class="s-form">
      <div class="f-item" >
        <span class="i-label">搜索区域</span>
        <div class="i-select" @click="openPopup('region')">
          <input :value="selectedFullName" placeholder="请选择区域" disabled />
          <i v-if="selectedFullName" class="iconfont icon-guanbi" @click.stop="clear"></i>
          <i v-else class="iconfont icon-xiajiantou"></i>
        </div>
      </div>
      <div class="f-item">
        <span class="i-label">区域用有人信息</span>
        <div class="i-info">
          <a-input disabled placeholder="拥有人姓名" :value="regionOwner.name">
            <template v-slot:prefix>
              <span class="input-prefix">拥有人姓名：</span>
            </template>
          </a-input>
          <a-input disabled placeholder="拥有人电话" :value="regionOwner.mobile">
            <template v-slot:prefix>
              <span class="input-prefix">拥有人电话：</span>
            </template>
          </a-input>
        </div>
      </div>
    </div>
    <div v-show="false" class="s-btn" :class="isButtonActive ? 'active' : ''" @click="applyShop">申请店铺</div>

    <van-popup v-model="popupShow" position="bottom" :style="{height: '60%'}">
      <region-picker custom-height="300" v-if="curPopup === 'region'" @confirm="confirmRegion"
        @cancel="popupShow = false"></region-picker>
      <block-picker v-if="curPopup === 'block'" ref="blockPicker" cancel-button-text="返回" @confirm="confirmBlock"
        @cancel="curPopup='region'" @no-data="handleNoData"></block-picker>
    </van-popup>

    <a-dialog
      :show="dialog.applyRes"
      :title="applyRes.title"
      :sub-title="applyRes.subTitle"
      :type="applyStatus"
      @close="dialog.applyRes=false"
    >
      <template v-slot:btns>
        <a-btn v-if="dialog.btnStatus === 1 " type="primary" @click="dialog.applyRes=false">重新选择</a-btn>
        <a-btn v-else type="primary" @click="dialog.applyRes=false">确认</a-btn>
      </template>
    </a-dialog>
  </div>
</template>

<script>
  import aBtn from '@/components/btn';
  import aInput from '@/components/input';
  import ADialog from '@/components/dialog';
  import RegionPicker from '@/components/picker/region';
  import BlockPicker from '@/components/picker/block';

  export default {
    components: {
      aBtn,
      aInput,
      RegionPicker,
      BlockPicker,
      ADialog
    },
    metaInfo() {
      return {
        title: '授权区域店铺申请'
      }
    },
    data() {
      return {
        curPopup: '',
        popupShow: false,
        dialog: {
          btnStatus:1,
          applyRes: false,
        },
        applyStatus: 'success',
        applyRes: {
          title: '该区域暂未授权！',
          subTitle: '该区域暂未授权，请重新选择。',
          type: 'unAuthorize'
        },
        cachePCDS: [],
        selectedBlock: {},
        agentInfo: {}
      }
    },
    computed: {
      selectedFullName() {
        let res = []
        if (this.cachePCDS.length && this.selectedBlock.id) {
          this.cachePCDS.forEach(r => {
            if (r.name) {
              res.push(r.name)
            }
          })
          res.push(this.selectedBlock.name)
        }
        return res.join('-')
      },
      regionOwner() {
        let name = this.$_.get(this.selectedBlock, 'regionAllocateAgentName')
        let mobile = this.$_.get(this.selectedBlock, 'regionAllocateAgentMobile')
        let allocateId = this.$_.get(this.selectedBlock, 'id')
        let isEnableRegionApply = this.$_.get(this.selectedBlock, 'isEnableRegionApply')
        return {
          name,
          mobile,
          allocateId,
          isEnableRegionApply
        }
      },
      isButtonActive() {
        if (this.selectedFullName) return true
        return false
      }
    },
    methods: {
      openPopup(type) {
        this.curPopup = type
        this.popupShow = true
      },
      confirmRegion(e) {
        this.curPopup = 'block'
        this.cachePCDS = e.values
        this.$nextTick(() => {
          this.$refs.blockPicker.setPageData(Object.assign(e.pcds, {isEnableUnlimitShopApply: 1}))
        })
      },
      confirmBlock(e) {
        let hasAgent = this.$_.get(e[0], 'id')
        if (hasAgent) {
          this.selectedBlock = e[0];
        } else {
          this.dialog.btnStatus = 1;
          this.dialog.applyRes = true
          this.applyStatus = 'error'
          this.applyRes = {
            title: '该区域暂未授权！',
            subTitle: '该区域暂未授权，请重新选择。',
            type: 'applyRes'
          }
        }
        this.popupShow = false
      },
      applyShop() {
        if (this.regionOwner.allocateId) {
          let {
            allocateId,
            name,
            mobile,
            isEnableRegionApply
          } = this.regionOwner
          this.go(`/apply/shop/add?allocateId=${allocateId || ''}&name=${name || ''}&mobile=${mobile ||
          ''}&regionFullName=${this.selectedFullName || ''}&isEnableRegionApply=${isEnableRegionApply || 0}`)
        } else {
          this.$toast('请选择区域')
        }
      },
      handleNoData() {
        this.dialog.btnStatus = 2;
        this.popupShow = false ;
        this.dialog.applyRes = true;
        this.applyStatus = 'error'
        this.applyRes = {
          title: '该区域未划分！',
          subTitle: '该区域未划分，请联系信息部玲玲。',
          type: ''
        }
      },
      clear() {
        this.cachePCDS = {};
        this.selectedBlock = {};
      }
    }
  }
</script>

<style lang="less" scoped>
  .shop-apply-search {
    padding: 0 30px;

    .s-header {
      margin: 30px 0;
      width: 690px;
      height: 240px;
    }

    .s-form {
      .f-item {
        margin-bottom: 50px;

        .i-label {
          margin-bottom: 20px;
          font-size: 34px;
          font-weight: bold;
          color: #333333;
        }

        .i-select {
          display: flex;
          width: 690px;
          height: 90px;
          border: 1px solid #999;

          input {
            flex: 1;
            padding-left: 20px;
            font-size: 30px;
            font-weight: 500;
            color: #999;
            border: none;
            background: #fff;
          }

          i {
            width: 90px;
            height: 90px;
            line-height: 90px;
            text-align: center;
            font-size: 45px;
          }
        }

        .i-info {
          /deep/.axq-input {
            margin-bottom: 20px;
            padding: 0 20px;
            border: 1px solid #999;
            border-radius: 4px;

            &__inner {
              background: #fff;
              text-align: right;
            }
          }

          .input-prefix {
            font-size: 30px;
            font-weight: 500;
            color: #333;
          }
        }
      }
    }

    .s-btn {
      position: fixed;
      bottom: 40px;
      width: 690px;
      height: 90px;
      border-radius: 10px;
      line-height: 90px;
      text-align: center;
      color: #fff;
      background-color: #f390c3;
    }

    .active {
      background-color: #db187c;
    }

    /deep/.axq-dialog__btns {
      width: 100%;

      .axq-btn {
        width: 200px !important;
        height: 60px;
        line-height: 60px;
        border-radius: 30px;
      }

      a-btn {
        &:not(:first-of-type) {
          margin-left: 20px;
        }
      }
    }
  }
</style>