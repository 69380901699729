<template>
  <div class="axq-empty">
    <img class="axq-empty-img" :src="src" />
    <span class="axq-empty-text" v-for="(t,i) in tips" :key="i">{{ t }}</span>
  </div>
</template>

<script>
  import {
    appConfig
  } from '@/config/app'
  export default {
    name: 'AxqEmpty',
    props: {
      src: {
        type: String,
        default: appConfig.staticUrl + 'empty.png'
      },
      tips: {
        type: Array,
        default: () => ['数据空空~']
      }
    }
  }
</script>

<style lang="less">
  .axq-empty {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &-img {
      margin-top: 223px;
      width: 240px;
      height: 240px;
    }

    &-text {
      font-size: 30px;
      color: #666;
      line-height: 50px;

      &:first-of-type {
        margin-top: 40px;
      }
    }
  }
</style>