/*
 * @Author: 码上talk|RC
 * @Date: 2021-07-03 15:34:12
 * @LastEditTime: 2022-01-11 16:52:44
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/store/user/getters.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */

export const getters = {
};
