/*
 * @Author: 码上talk|RC
 * @Date: 2021-07-03 15:34:12
 * @LastEditTime: 2022-01-11 16:49:04
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/store/user/mutation-types.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
export const SET_MEMBER_INFO = 'SET_MEMBER_INFO';