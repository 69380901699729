import Entity from '../../entity.class.js';

const _tableField = {
  distributorPId: {
    type: 'int',
    default: 0
  },
  type: {
    type: 'int',
    default: 0
  },
  levelId: {
    type: 'int',
    default: 0
  },
  username: {
    type: 'string',
    default: ''
  },
  nickname: {
    type: 'string',
    default: ''
  },
  mobile: {
    type: 'string',
    default: ''
  },
  fullAddress: {
    type: 'string',
    default: ''
  },
  inviteCode: {
    type: 'string',
    default: ''
  },
  inviteCodeWxEntry: {
    type: 'string',
    default: ''
  },
  memberAccount: {
    type: 'Object',
    default: {}
  },
  distributor: {
    type: 'Object',
    default: {}
  },
  parent: {
    type: 'Object',
    default: {}
  }
};

class Member extends Entity {
  static _requestConfig = {
    app: 'm',
    domain: 'member'
  }

  static _form = {}

  static options = {}

  constructor (member) {
    super(member, { _tableField });
  }
}

export default Member;
