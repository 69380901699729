/*
 * @Author: 码上talk|RC
 * @Date: 2021-05-03 22:30:33
 * @LastEditTime: 2021-06-04 00:01:27
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-admin/src/model/base/request.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
import apiJson from '@/config/api';
import { httpLib } from '@/libs/js/http';

export class Request {
  _getRequestBaseUrl (url) {
    let finalBaseUrl = url.dev;

    if (process.env.NODE_ENV === 'local') {
      finalBaseUrl = url.lcoal;
    }

    if (process.env.NODE_ENV === 'test') {
      finalBaseUrl = url.test;
    }

    if (process.env.NODE_ENV === 'production') {
      finalBaseUrl = url.prod;
    }

    return finalBaseUrl;
  }

  _getConfig (app, domain, method) {
    return {
      fullUrl: this._getRequestBaseUrl(apiJson[app].url) + apiJson[app].domain[domain][method].url
    };
  }

  do (app, domain, method, form) {
    const { params, data } = form;
    return httpLib.request({
      baseURL: this._getRequestBaseUrl(apiJson[app].url),
      url: apiJson[app].domain[domain][method].url,
      params,
      data
    });
  }
}
