<!--
 * @Author: 码上talk|RC/3189482282@qq.com
 * @Date: 2022-01-08 08:24:00
 * @LastEditTime: 2022-06-23 16:07:05
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/apply/index.vue
-->
<template>
  <div class="region-search">
    <div class="s-main">
      <region-card
        class="m-item"
        title="区域查询"
        :img-url="'apply/index/region.png'|prefixOssUrl"
        @click="go('/apply/region/search/index')"
      ></region-card>
      <!-- <region-card
        class="m-item"
        title="店铺申请"
        :img-url="'apply/index/shop.png'|prefixOssUrl"
        @click="go('/apply/shop/search')"
      ></region-card> -->
      <region-card
        class="m-item"
        title="店铺查询"
        :img-url="'apply/index/shop.png'|prefixOssUrl"
        @click="developing"
      ></region-card>
      <region-card
        class="m-item"
        title="我的申请"
        :img-url="'apply/index/my.png'|prefixOssUrl"
        @click="go('/apply/approval/list')"
      ></region-card>
      <region-card
        class="m-item"
        title="店铺保证金"
        :img-url="'apply/index/deposit.png'|prefixOssUrl"
        @click="developing"
      ></region-card>
    </div>
  </div>
</template>

<script>
import RegionCard from './@fragment/region-card';
import { sessionLib } from '@/libs/js/session';
export default {
  components: { RegionCard },
  metaInfo () {
    return {
      title: '区域查询'
    }
  },
  created () {
    // this.rep('/apply/pending');
    this.init();
  },
  methods: {
    init () {
      const { type, token } = this.$route.query;
      const t2k = {
        1: 'api-member',
        2: 'api-distributor'
      }
      sessionLib.setAppKey(t2k[type]);
      sessionLib.set(token);
    }
  }
}
</script>

<style lang="less" scoped>
.region-search {
  .s-main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    background: #fff;
    .m-item {
      margin-top: 30px;
      &:nth-of-type(n + 1) {
        margin-left: 30px;
      }
    }
  }
}
</style>