/*
 * @Author: 码上talk|RC
 * @Date: 2021-05-05 09:21:08
 * @LastEditTime: 2022-05-05 09:36:18
 * @LastEditors: 码上talk|RC
 * @Description:
 * @FilePath: /web-m/src/model/entity/approval/index.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */

import Entity from '../../entity.class.js';
import form from '../../json/form/approval/approvalSubShop';
import options from '../../json/options/approval';

const _tableField = {
  id: {
    type: 'int',
    default: 0
  },
  typeId: {
    type: 'int',
    default: 0
  },
  name: {
    type: 'string',
    default: ''
  },
  sn: {
    type: 'string',
    default: ''
  },
  applierName:{
    type: 'string',
    default: ''
  },
  applierMobile:{
    type: 'string',
    default: ''
  },
  workflowName :{
    type: 'string',
    default: ''
  },
  status: {
    type: 'int',
    default: 0
  },
  goodsItemsQuantity: {
    type: 'int',
    default: 0
  },
  goodsItemsQuantityNoStockOut: {
    type: 'int',
    default: 0
  },
  amount: {
    type: 'int',
    default: 0
  },
  purchanseCreateTime :{
    type: 'string',
    default: ''
  },
  purchanseType:{
    type: 'int',
    default: 0
  },
  isNewDistributor: {
    type: 'int',
    default: 0
  },
  provinceName: {
    type: 'string',
    default: ''
  },
  cityName: {
    type: 'string',
    default: ''
  },
  districtName: {
    type: 'string',
    default: ''
  },
  regionAllocateName: {
    type: 'string',
    default: ''
  }
};

class Approval extends Entity {
  static _requestConfig = {
    app: 'm',
    domain: 'approval'
  }

  static _form = form

  static _options = options

  constructor (approval) {
    super(approval, { _tableField });
  }
}

export default Approval;
