<!--
 * @Author: 码上talk|RC
 * @Date: 2021-06-07 14:07:12
 * @LastEditTime: 2022-01-18 08:09:36
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/trace/query.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="trace-query">
    <div class="q-search">
      <div class="s-wrap">
        <div class="w-input">
          <input type="span" placeholder="溯源码/防伪码" v-model="code" />
          <i class="iconfont icon-sousuo"></i>
        </div>
        <div class="w-btn" @click="doQuery">
          <span>查询</span>
        </div>
      </div>
    </div>
    <div class="q-main">
      <div class="m-list" v-if="isOk && $_.get(info, 'goodsItems.id')">
        <div class="l-goods-items">
          <div class="gi-cover">
            <img :src="thumbnailUrl" />
          </div>
          <div class="gi-content">
            <div class="c-item big">
              <span>商品名称：</span>
              <span>{{ blankGet(info, 'goodsItems.goods.name') }}</span>
            </div>
            <div class="c-item">
              <span>商品款号：</span>
              <span>{{ blankGet(info, 'goodsItems.name') }}</span>
            </div>
            <div class="c-item">
              <span>商品规格：</span>
              <span>{{ goodsSpec }}</span>
            </div>
            <div class="c-item red">
              <span>零售价：</span>
              <span>￥{{ blankGet(info, 'goodsItems.amount') }}</span>
            </div>
          </div>
        </div>
        <div class="l-timeline">
          <template v-for="(item, key) in $_.get(info, 'traceCode2LogInfoNodeList', [])">
            <div class="t-item" :key="key" v-if="item.type === 1">
              <div class="i-title">
                <span>入库信息</span>
              </div>
              <div class="i-content">
                <div class="c-item">
                  <span>入库人</span>
                  <span>{{ blankGet(item, 'info.stockIn.documentMaker.nickname') }}</span>
                </div>
                <div class="c-item">
                  <span>仓库入库时间</span>
                  <span>{{ replaceJsonTime(blankGet(item, 'info.stockIn.createTime')) }}</span>
                </div>
              </div>
            </div>
            <div class="t-item" :key="key" v-if="item.type === 2">
              <div class="i-title">
                <span>出库信息</span>
              </div>
              <div class="i-content">
                <div class="c-item">
                  <span>出库人</span>
                  <span>{{ blankGet(item, 'info.stockOut.documentMaker.nickname') }}</span>
                </div>
                <div class="c-item">
                  <span>出库时间</span>
                  <span>{{ blankGet(item, 'info.stockOut.createTime') }}</span>
                </div>
                <div class="c-item">
                  <span>收件人</span>
                  <span>{{ blankGet(item, 'info.stockOut.purchanse.recipient') }}</span>
                </div>
                <div class="c-item">
                  <span>下单时间</span>
                  <span>{{ blankGet(item, 'info.stockOut.purchanse.createTime') }}</span>
                </div>
                <div class="c-item">
                  <span>下单客服</span>
                  <span>{{ blankGet(item, 'info.stockOut.purchanse.documentMaker.nickname') }}</span>
                </div>
                <div class="c-item">
                  <span>收货地址</span>
                  <span style="width:70%;">{{ blankGet(item, 'info.stockOut.purchanse.fullAddress') }}-{{ blankGet(item,
                    'info.stockOut.purchanse.recipient') }}/{{ blankGet(item, 'info.stockOut.purchanse.recipientMobile')
  }}</span>
                </div>
              </div>
            </div>
            <div class="t-item" :key="key" v-if="item.type === 3">
              <div class="i-title">
                <span>店铺入库信息</span>
              </div>
              <div class="i-content">
                <div class="c-item">
                  <span>入库人</span>
                  <span>{{ blankGet(item, 'info.operator.nickname') }}</span>
                </div>
                <div class="c-item">
                  <span>入库时间</span>
                  <span>{{ blankGet(item, 'info.createTime') }}</span>
                </div>
                <div class="c-item">
                  <span>入库店铺</span>
                  <span>{{ blankGet(item, 'info.shop.name') }}</span>
                </div>
              </div>
            </div>
            <div class="t-item" :key="key" v-if="item.type === 4">
              <div class="i-title">
                <span>店铺出库信息</span>
              </div>
              <div class="i-content">
                <div class="c-item">
                  <span>店铺名：</span>
                  <span>{{ blankGet(item, 'info.shop.name') }}</span>
                </div>
                <div class="c-item">
                  <span>出库人</span>
                  <span>{{ blankGet(item, 'info.operator.nickname') }}</span>
                </div>
                <div class="c-item">
                  <span>出库时间</span>
                  <span>{{ blankGet(item, 'info.createTime') }}</span>
                </div>
                <div class="c-item">
                  <span>购买会员</span>
                  <span>{{ blankGet(item, 'info.member.nickname') }}</span>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="m-no-data" v-else-if="loader == null">
        <img src="/image/query-no-data.png" alt />
        <p>暂无信息</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Request } from '@/model/request'
import { model } from '@/model';

const { Oss } = model.collection;
export default {
  data() {
    return {
      loader: null,
      request: new Request(),
      isOk: false,
      type: 1,
      code: this.$route.query.qrCode || this.$route.query.captcha,
      info: {},
      entitys: {
        Oss
      }
    }
  },
  filters: {
    type2str(t) {
      const t2s = {
        1: '产品入库',
        2: '产品出库',
        3: '店铺入库'
      };
      return t2s[t];
    }
  },
  computed: {
    goodsSpec() {
      let spec = JSON.parse(this.info.goodsItems.specJson)
      return spec.find(v => v.key === '尺码').value + '/' + spec.find(v => v.key === '颜色').value
    },
    thumbnailUrl() {
      const {thumbnail} = this.info.goodsItems;
      if (thumbnail) {
        return new this.entitys.Oss({ filename: this.info.goodsItems.thumbnail || '' }).fullUrl();
      }
      // 如果无缩略图,则用相册中的第一张图
      const {goods} = this.info.goodsItems;
      if(goods && goods.gallery){
        const gallery = JSON.parse(goods.gallery);
        if(gallery.length > 0){
          return gallery[0];
        }
      }
      return "";
    }
  },
  methods: {
    init() {
      this.code && this.doQuery();
    },
    doQuery() {
      if (!this.code) {
        this.$toast.fail('请输入溯源码/防伪码')
        return;
      }
      let qrCode = null;
      let captcha = null;
      if (this.isTraceCode(this.code)) {
        qrCode = this.code;
      } else {
        captcha = this.code;
      }
      this.isOk = false;
      this.request.do('open', 'trace', 'logInfo', { params: {}, data: { query: { qrCode, captcha } } }).then(res => {
        const { status, data } = res;
        this.loader.clear();
        console.log("data",data);
        if (status) {
          //this.$toast.success('获取溯源信息成功');
          // AOXUEQI-4202
          const find = data.traceCode2LogInfoNodeList.find(item => item.type === 2)
          if(find && find.info.recipient.indexOf("G_") === 0){
            this.request.do("agt", "stock", "stockOutInfo", {
              params: {},
              data: {  
                query: { id: find.info.stockOut.id }
              }
            }).then(res => {
              find.info.stockOut.documentMaker.nickname = res.data.documentMaker.nickname
              find.info.stockOut.createTime = res.data.createTime
              find.info.stockOut.purchanse.recipient = res.data.orderForm.recipient
              find.info.stockOut.purchanse.createTime = res.data.orderForm.createTime
              find.info.stockOut.purchanse.documentMaker.nickname = ""
              find.info.stockOut.purchanse.fullAddress = res.data.orderForm.recipientAddress
            })
          }
          this.info = data;
          this.isOk = true;
        } else {
          this.$toast.fail('获取溯源信息失败');
        }
      });
    }
  },
  created() {

    this.loader = this.$toast.loading('正在获取溯源信息..');
    this.init();
  }
}
</script>

<style lang="less">
.trace-query {
  .q-search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background: white;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);

    .s-wrap {
      height: 90px;
      display: flex;
      align-items: center;
      padding: 0 20px;

      .w-input {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0 20px;
        margin-right: 20px;
        background: #f7f7f7;

        input {
          flex: 1;
          border: none;
          outline: none;
          background: none;
          font-size: 30px;
        }
      }

      .w-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 50px;
        background: #e71b8e;

        span {
          color: white;
        }
      }
    }
  }

  .q-main {
    padding: 90px 20px;

    .m-list {
      padding-top: 20px;

      .l-goods-items {
        display: flex;

        .gi-cover {
          width: 160px;
          height: 160px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .gi-content {
          flex: 1;
          padding-left: 20px;

          .c-item {
            display: flex;

            span {
              font-size: 30px;
            }

            &:not(:first-child) {
              margin-top: 20px;
            }
          }

          .big {
            font-weight: 600;
            color: #262626;
          }

          .red {
            span {
              color: #db187c;
            }
          }
        }
      }

      .l-timeline {
        padding-top: 20px;

        .t-item {
          .i-title {
            position: relative;
            display: flex;
            align-items: center;
            height: 60px;
            padding-left: 20px;

            span {
              font-size: 34px;
              font-weight: 600;
              color: #000000;
            }

            &:before {
              position: absolute;
              content: "";
              top: 5px;
              left: 0;
              height: 50px;
              width: 10px;
              background: #e71b8e;
            }
          }

          .i-content {
            margin: 30px 0;
            padding: 20px;
            background: #f7f7f7;

            .c-item {
              display: flex;
              align-items: center;
              justify-content: space-between;

              span {
                font-size: 30px;
              }
            }
          }

          &:not(:first-child) {
            margin-top: 20px;
          }
        }
      }
    }

    .m-no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 150px;

      img {
        width: 477px;
        height: 311px;
      }

      p {
        padding-top: 50px;
        font-size: 28px;
      }
    }
  }
}</style>