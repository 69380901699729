<!--
 * @Author: 码上talk|RC
 * @Date: 2021-08-04 17:34:56
 * @LastEditTime: 2022-01-14 17:38:44
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/apply/approval/@fragment/steps.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="axq-steps">
    <div class="axq-steps-item" :class="{active:active>=i}" v-for="(s,i) in steps" :key="i">
      <span class="step-value">{{ i+1 }}</span>
      <span class="step-name">{{ s.name }}</span>
      <div class="step-line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
    steps: Array
  }
}
</script>

<style lang="less">
.axq-steps {
  display: flex;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    flex-shrink: 0;
    .step-value {
      position: relative;
      z-index: 1;
      width: 40px;
      height: 40px;
      line-height: 45px;
      text-align: center;
      color: #fff;
      font-size: 24px;
      background: #ccc;
      border-radius: 50%;
    }
    .step-name {
      margin-top: 21px;
      font-size: 28px;
      color: #999;
    }
    &::after,
    &::before {
      content: "";
      position: absolute;
      z-index: 0;
      top: 20px;
      height: 2px;
      background: #ccc;
      width: 50%;
    }
    &::before {
      left: 0;
    }
    &::after {
      right: 0;
    }
    &.active {
      .step-value {
        background: #db187c;
      }
      .step-name {
        color: #db187c;
      }
      &::before,
      &::after {
        background: #db187c;
      }
    }
    &:first-of-type {
      &::before {
        background: transparent;
      }
      &.active {
        &::after {
          background: #db187c;
        }
      }
    }
    &:last-of-type {
      &::after {
        background: transparent;
      }
      &.active {
        &::before {
          background: #db187c;
        }
      }
    }
  }
}
</style>