import { get } from 'lodash'

const ApprovalType = {
  REGION: 1,
  SHOP: 2
}

const ApprovalStatus = {
  AUDITING: 1,
  SUCCESS: 2,
  REJECT: 3,
  CANCEL: 4
}
class ProgressManager {
  static getTypeName (data) {
    return get(data, 'typeId') == ApprovalType.SHOP ? '店铺授权' : '区域授权'
  }
  static getTypeKey (data) {
    return get(data, 'typeId') == ApprovalType.SHOP ? 'approvalSubShop' : 'approvalSubRegionAllocateAgent'
  }
  static getCurWorkFlow (data) {
    let curWorkflow = {}
    let workFlows = get(data, 'approvalWorkflowList', []) || []
    let timelines = get(data, 'approvalTimelineList', []) || []
    if (timelines[0]) {
      curWorkflow = workFlows.find((w) => {
        return w.id == timelines[0].workflowId
      })
    }
    return curWorkflow
  }
  /**
   * @param {*} data 
   * @breif  ApplyCard 获取当前节点的具体进度,进度分页没有给出WorkFlow
   * @deprecated
   */
  static getCurWorkFlowStatus (data) {
    let curWorkFlow = ProgressManager.getCurWorkFlow(data)
    let res = ''
    if (curWorkFlow.id) {
      let typeKey = ProgressManager.getTypeKey(data)
      res = curWorkFlow.name + '审核中'
      if (curWorkFlow.step == 1) {
        let typeName = ProgressManager.getTypeName(data)
        let isDepositPayerFill = data[typeKey].depositPayer
        res = !isDepositPayerFill ? typeName + '审核通过→缴纳保证金' : '保证金审核中'
      } else if (curWorkFlow.step == 2) {
        let isAddressFill = data[typeKey].addressFull
        res = !isAddressFill ? '保证金审核通过→提交店铺地址' : '店铺地址审核中'
      } else if (curWorkFlow.step == 3) {
        let isImgListFill = data[typeKey].fileTenancyAgreement
        res = !isImgListFill ? '店铺地址通过→提交签约申请' : '签约申请审核中'
      } else if (curWorkFlow.step == 4) {
        let isImgListFill = data[typeKey].fileFixture
        res = !isImgListFill ? '签约申请通过→提交装修申请' : '装修申请审核中'
      }
    } else {
      res = ProgressManager.getTypeName(data) + '审核中'
    }
    return res
  }

  constructor () {
    this.info = {}
    this.step = -1
    this.isFormShow = false
    this.isResubmit = 0
  }
  get timeLine () {
    let tl = get(this, 'info.approvalTimelineList', [])
    return tl
  }
  get flowList () {
    let tl = get(this, 'info.approvalWorkflowList', [])
    return tl
  }
  get curWorkflow () {
    let latestTL = this.timeLine[0] || {}
    if (!latestTL.id) return {}
    let curWorkflow = this.flowList.find((f) => {
      return f.id == latestTL.workflowId
    })
    return curWorkflow || {}
  }
  get latestTimeline () {
    return this.timeLine[0] ? this.timeLine[0] : {}
  }
  get latestPassTimeLine () {
    let passed = this.timeLine.filter((t) => {
      return t.status === 1
    })
    return passed[0] || {}
  }
  get latestAuditStatus () {
    // 1通过,2不通过
    return this.latestTimeline.status ? this.latestTimeline.status : 1
  }
  get latestAuditText () {
    return this.latestAuditStatus == 1 ? '通过' : '未通过'
  }

  get isAuditing () {
    if (this.info.status === !ApprovalStatus.AUDITING) return false;
    if (get(this.timeLine, '[0].status') === 2) {
      return false;
    }
    let regionApplyForm = get(this.info, this.typeKey, {})
    if (this.step == 0) {
      return !!regionApplyForm.depositPayer;
    } else if (this.step == 1) {
      return !!regionApplyForm.addressFull;
    } else if (this.step == 2) {
      return this.tenancyImgList.length != 0;
    } else if (this.step == 3) {
      return this.fixtureImgList.length != 0;
    } else if (this.step == 5) {
      return this.fixtureImgList.length != 0;
    }
  }
  get type () {
    return get(this.info, 'typeId')
  }
  get typeKey () {
    return this.type == ApprovalType.SHOP ? 'approvalSubShop' : 'approvalSubRegionAllocateAgent'
  }
  get applyType () {
    return this.type == ApprovalType.SHOP ? '店铺授权' : '区域授权'
  }
  // 不通过对应的图片展示
  get statusInfo () {
    let res = {}
    if (this.step == -1) {
      let typeText = this.applyType
      res.src = 'apply/approval/audit/auditing.png'
      res.tips = [
        `您的${typeText}申请正在审核中...`,
        '请留意工作人员电话！'
      ]
    }
    let step = this.step,
      auditingSrc = 'apply/approval/audit/auditing.png'
    if (this.info.status == 3) {
      res.src = 'apply/approval/audit/reject.png'
      let typeText = this.applyType
      res.tips = [
        `很遗憾，${typeText}申请未通过`
      ]
    } else if (this.info.status === 4) {
      res.src = 'apply/approval/audit/reject.png'
      let typeText = this.applyType
      res.tips = [
        `${typeText}申请已取消`
      ]
    }
    else if (this.info.status == 2) {
      res.src = 'apply/approval/audit/pass.png'
      res.tips = ['恭喜您！店铺装修审核已通过！', '开店成功！']
    } else if (this.info.status == 1) {
      res.src = 'apply/approval/audit/pass.png'
      if (step == 0) {
        if (this.latestAuditStatus === 2) {
          res.src = 'apply/approval/audit/reject.png'
          res.tips = ['很遗憾,保证金申请未通过！']
        } else if (this.trueAuditStep == 1 && !this.isAuditing && this.latestAuditStatus == 1) {
          res.tips = [`恭喜您！${this.applyType}申请已通过！`]
        } else {
          if (this.isAuditing) {
            res.src = auditingSrc
            res.tips = ['您的保证金正在审核中...']
          } else {
            res.tips = ['恭喜您！市场保证金审核已通过！']
          }
        }
      } else if (step == 1) {
        if (this.latestAuditStatus === 2) {
          res.src = 'apply/approval/audit/reject.png'
          res.tips = ['很遗憾,地址申请未通过！']
        } else if (this.isAuditing && this.latestAuditStatus === 1) {
          res.src = auditingSrc
          res.tips = ['您的店铺地址正在审核中...']
        } else {
          res.tips = ['恭喜您！市场保证金审核已通过']
        }
      } else if (step == 2) {
        if (this.latestAuditStatus === 2) {
          res.src = 'apply/approval/audit/reject.png'
          res.tips = ['很遗憾,店铺签约申请未通过！']
        } else if (this.isAuditing) {
          res.src = auditingSrc
          res.tips = ['您的店铺合同正在审核中...']
        } else {
          res.tips = ['恭喜您！店铺店铺地址审核已通过！']
        }
      } else if (step == 3) {
        if (this.latestAuditStatus === 2) {
          res.src = 'apply/approval/audit/reject.png'
          res.tips = ['很遗憾,装修申请未通过！']
        } else if (this.isAuditing) {
          res.src = auditingSrc
          res.tips = ['您的店铺装修正在审核中...']
        } else {
          res.tips = ['恭喜您！签约审核已通过！']
        }
      }
    }
    return res
  }

  // 表单值
  get applierName () {
    return get(this.info, `${this.typeKey}.applier.nickname`, '')
  }
  get applierMobile () {
    return get(this.info, `${this.typeKey}.applier.mobile`, '')
  }
  get regionAllocateName () {
    let p = get(this.info, `${this.typeKey}.regionAllocate.provinceName`, ''),
      c = get(this.info, `${this.typeKey}.regionAllocate.cityName`, ''),
      d = get(this.info, `${this.typeKey}.regionAllocate.districtName`, ''),
      n = get(this.info, `${this.typeKey}.regionAllocate.name`, '')
    return p + c + d + n
  }
  get addressFull () {
    let res = get(this.info, `${this.typeKey}.addressFull`, '')
    return res
  }
  get addressArea () {
    let res = get(this.info, `${this.typeKey}.addressArea`, '')
    return res
  }
  get addressContact () {
    let res = get(this.info, `${this.typeKey}.addressContact`, '')
    return res
  }
  get tenancyImgList () {
    let regionApplyForm = get(this.info, this.typeKey, {}) || {}
    let json = regionApplyForm.fileTenancyAgreement || '[]'
    let imgList = []
    try {
      imgList = JSON.parse(json)
    } catch (error) {
      //
    }
    if (!Array.isArray(imgList)) {
      imgList = []
    }
    return imgList

  }
  get fixtureImgList () {
    let regionApplyForm = get(this.info, this.typeKey, {})
    let json = regionApplyForm.fileFixture
    let imgList = []
    try {
      imgList = JSON.parse(json)
    } catch (error) {
      //
    }
    if (!Array.isArray(imgList)) {
      imgList = []
    }
    return imgList

  }
  get remark () {
    return this.latestTimeline.remark
  }
  get workFlowStep () {
    return this.flowList.findIndex((f) => {
      return f.id == this.latestTimeline.workflowId
    })
  }
  /**
   * 已到的审核节点
   */
  get trueAuditStep () {
    return this.flowList.findIndex((f) => {
      return f.id == this.info.workflowId
    })
  }
  setApprovalInfo (data) {
    this.info = data
    this.updateStepFromInfo()
  }
  showForm () {
    this.isFormShow = true
  }
  updateStepFromInfo () {
    // 初始
    if (this.timeLine.length == 0) return -1
    // 组件节点
    let index = this.trueAuditStep - 1
    if (this.info.status == 3) {
      审核不通过
      if (this.trueAuditStep === 4) {
        if (this.latestTimeline.status === 2) {
          index = 4
        }
      }
      index++
    } else if (this.info.status == 2) {
      index = 5
    } else {
    }
    this.step = index
    return index
  }
  buildForm (withForm = {}) {
    let form = {
      id: get(this.info, 'id'),
      workflowId: get(this.info, 'workflowId'),
      status: get(this.info, 'status'),
      typeId: get(this.info, 'typeId'),
    }
    let oldForm = get(this.info, this.typeKey, {}) || {}
    let id = oldForm.id,
      type = oldForm.type,
      applierId = oldForm.applierId,
      creatorId = oldForm.creatorId,
      regionAllocateId = oldForm.regionAllocateId

    let fileAuthorizeAgreement = oldForm.fileAuthorizeAgreement || '',
      depositAmount = oldForm.depositAmount || 0,
      depositPayer = oldForm.depositPayer || '',
      depositPayTime = oldForm.depositPayTime || '',
      fileDeposit = oldForm.fileDeposit,
      addressFull = oldForm.addressFull,
      addressArea = oldForm.addressArea,
      addressContact = oldForm.addressContact,
      inviteMemberId = oldForm.inviteMemberId,
      superiorDistributorId = oldForm.superiorDistributorId,
      topDistributorId = oldForm.topDistributorId,
      fileTenancyAgreement = oldForm.fileTenancyAgreement,
      fileFixture = oldForm.fileFixture

    form[this.typeKey] = {
      id,
      type,
      applierId,
      creatorId,
      regionAllocateId,
      depositIsRequire: 1,

      fileAuthorizeAgreement,
      depositAmount,
      depositPayer,
      depositPayTime,
      fileDeposit,
      addressFull,
      addressArea,
      addressContact,
      inviteMemberId,
      superiorDistributorId,
      topDistributorId,
      fileTenancyAgreement,
      fileFixture,
      ...withForm
    }
    return form
  }
  // 更新info，用于判断step
  updateInfo (form) {
    this.info[this.typeKey] = {
      ...this.info[this.typeKey],
      ...form
    }
  }

  /**
   * @param {number} ApprovalStatus Enum
   * @returns {Promise}
   */
  remoteUpdateStatus (v) {
    let form = {
      id: this.info.id,
      status: v
    }
  }
}
export default ProgressManager