/*
 * @Author: 码上talk|RC
 * @Date: 2021-07-03 15:34:12
 * @LastEditTime: 2022-01-13 15:03:29
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/components/picker/PickerManager.class.js
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
 */
class PickerManager {
  constructor (columns = []) {
    this.columns = columns
    this.columnsCount = columns.length
    this.pickerColumns = []
  }
  setPickerColumns (cs = []) {
    this.pickerColumns = cs
  }
  addColumns (column = {}) {
    this.columns.push(column)
    this.columnsCount++
    this.pickerColumns.push({
      ...column,
      values: column.values.map(i => i.name)
    })
  }
  changeColumnValues (values = [], index) {
    let target = this.columns[index]
    if (target) {
      target.values = values
      this.pickerColumns[index].values = values.map(i => i.name)
    }
  }
  pushColumnValues (values = [], index) {
    let target = this.columns[index]
    if (!target) {
      this.columns[index] = {
        defaultIndex: 0,
        values: []
      }
      this.pickerColumns[index] = {
        defaultIndex: 0,
        values: []
      }
    }
    target = this.columns[index]
    target.values = target.values.concat(values)
    values.forEach(i => {
      this.pickerColumns[index].values.push(i.name)
    })
  }
  getValues (pickerValues) {
    let count = 0,
        arr = []
    while (count < pickerValues.length) {
      let regionArr = this.columns[count].values
      let target = regionArr.find(r => {
        return r.name == pickerValues[count]
      })
      if (target) {
        arr.push(target)
      } else {
        arr.push({})
      }
      count++
    }
    return arr
  }
}
export default PickerManager