<template>
  <button :class="classes" :disabled="disabled ||loading" @click="handleClick">
    <van-loading v-if="loading" :size="loadingSize"></van-loading>
    <slot></slot>
  </button>
</template>

<script>
  export default {
    name: 'AxqBtn',
    props: {
      type: {
        type: String,
        default: 'default'
      },
      hollow: Boolean,
      size: String,
      disabled: Boolean,
      loading: Boolean,
      round: Boolean
    },
    data() {
      return {}
    },
    computed: {
      classes() {
        let str = `axq-btn axq-btn--${this.type}`
        if (this.size) str += ` axq-btn--${this.size}`
        if (this.disabled || this.loading) str += ' axq-btn--disabled'
        if (this.hollow) str += ' axq-btn--hollow'
        if (this.loading) str += ' axq-btn--loading'
        if (this.round) str += ' axq-btn--round'
        return str
      },
      loadingSize() {
        let res = '20px'
        if (this.size == 'large') {
          res = '18px'
        }
        return res
      }
    },
    methods: {
      handleClick(e) {
        this.$emit('click')
        e.preventDefault()
        e.stopPropagation()
      }
    }
  }
</script>

<style lang="less" scoped>
  .axq-btn {
    display: inline-block;
    font-size: 30px;
    color: #dc187d;
    background: #fff;
    border-color: transparent;
    overflow: hidden;
    transition: all .3s linear;

    &::after {
      transform: none;
      width: 100%;
      height: 100%;
      border-color: transparent;
      border: 1px solid #db197b;
    }

    &--text {
      background: transparent;
      box-shadow: none;
      color: #dc187d;

      &::after {
        border: none;
      }
    }

    &--sub {
      color: #fff;
      background: #ccc;
    }

    &--primary {
      background: #DB187C;
      color: #fff;

      &[disabled] {
        background: #F390C3;
        color: #fff;

        &::after {
          border-color: #F390C3;
        }
      }
    }

    &--info {
      background: #DB187C;
      color: #fff;

      &[disabled] {
        background: #F390C3;
        color: #fff;
      }
    }

    &--large {
      height: 90px;
      line-height: 90px;
      font-size: 32px;
    }

    &--small {
      height: 40px;
      line-height: 40px;
      font-size: 24px;
    }

    &--round {
      border-radius: 40px;

      &::after {
        border-radius: 40px;
      }

      &.axq-btn--large,
      &::after {
        border-radius: 45px;
      }
    }

    &:active {
      filter: brightness(0.8);
    }

    &--hollow {
      &.axq-btn--primary {
        background: transparent;
        color: #dc187d;
        border: none;
        border-color: transparent;

        &::after {
          border-radius: 30px;
        }
      }
    }

    van-loading {
      margin-right: 24px;
    }
  }
</style>