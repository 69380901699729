<!--
 * @Author: 码上talk|RC
 * @Date: 2021-08-04 11:00:51
 * @LastEditTime: 2022-02-26 16:45:49
 * @LastEditors: 码上talk|RC
 * @Description: 
 * @FilePath: /web-m/src/views/apply/approval/list.vue
 * @微信:  13680065830
 * @邮箱:  3189482282@qq.com
 * @oops: Just do what I think it is right
-->
<template>
  <div class="my-apply">
    <van-sticky :offset-top="0">
      <div class="a-tabs">
        <div class="t-tab" :class="{'t-tab_active':type==1}" @click="changeType(1)">区域</div>
        <div class="t-tab" :class="{'t-tab_active':type==2}" @click="changeType(2)">店铺</div>
      </div>
    </van-sticky>
    <div class="apply-list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" success-text="刷新成功">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <apply-card v-for="(a,i) in listData" :key="i" :data="a"></apply-card>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
  import ApplyCard from './@fragment/apply-card'

  import {
    model
  } from '@/model';

  const {
    Approval
  } = model.collection;

  export default {
    components: {
      ApplyCard
    },
    metaInfo() {
      return {
        title: '我的申请'
      }
    },
    data() {
      return {
        type: 1,
        loading: false,
        finished: false,
        refreshing: false,
        page: {
          params: {
            pageIndex: 1,
            pageSize: 10
          },
          form: {
            query: {
              typeId: 1
            }
          },
          total: 0
        },
        listData: []
      }
    },
    methods: {
      init() {
        this.listData = [];
        this.page.params.pageIndex = 1;
        this.getApprovalPage();
      },
      onLoad() {
        if (!this.finished) {
          this.getApprovalPage();
        }
      },
      onRefresh() {
        this.refreshing = false;
        this.init();
      },
      getApprovalPage() {
        Approval.sendApi('page', {
          params: this.page.params,
          data: this.page.form
        }).then(res => {
          const {
            status,
            data,
            page
          } = res;
          if (status) {
            this.loading = false;
            this.page.total = page.total;
            this.listData = this.listData.concat(data);
            this.finished = (this.listData.length === this.page.total)
            this.page.params.pageIndex++;
          }
        })
      },
      changeType(v) {
        this.page.form.query.typeId = v
        this.type = v
        this.init();
      }
    }
  }
</script>

<style lang="less">
  .my-apply {
    padding: 0 30px;
    background: #f6f6f6;

    .a-tabs {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0;
      background: #f6f6f6;

      .t-tab {
        margin: 0 20px;
        height: 60px;
        line-height: 60px;
        font-size: 36px;

        &_active {
          position: relative;
          color: #db187c;

          &::after {
            content: "";
            position: absolute;
            top: 50px;
            left: 35px;
            transform: translateX(-50%);
            width: 60px;
            height: 5px;
            background: #db187c;
          }
        }
      }
    }

    .apply-list {
      flex: 1;
    }

    .apply-card {
      margin-top: 30px;
    }


  }
</style>